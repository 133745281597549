import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const RecursosPage = () => {

  useEffect(() => {
     AOS.init()
  }, []);


  return (
    <main>
      <Seo
        title="Recursos do Sistema wEstoque 🧩 | Controle Completo de Estoque, Vendas, PDV e Financeiro" 
		description="Descubra os principais recursos do wEstoque, um sistema online para gestão de estoque, vendas e PDV. Controle múltiplos estoques, empresas e caixas com nosso ERP. Gerencie finanças, emita notas fiscais e centralize operações com segurança em tempo real. Experimente grátis!"
		keywords={[
			`sistema pdv online`, 
			`controle de estoque online`, 
			`gestão de múltiplas empresas`, 
			`ERP para lojas`, 
			`controle de vendas e financeiro`, 
			`emissão de notas fiscais`, 
			`software para controle de estoques`, 
			`gestão de empresas e caixas`, 
			`sistema de vendas`, 
			`segurança de dados`, 
			`gestão de lojas`,
			`Sistema de controle de estoque`,
			`Gestão de PDV online`,
			`ERP para múltiplas lojas`,
			`Controle financeiro e fiscal`,
			`Gestão de vendas e estoques`,
			`Emissão de notas fiscais`,
			`Software de gestão empresarial`,
			`Controle de múltiplos caixas`,
			`Gestão integrada de empresas`,
			`recursos`,
			`sistema pdv`,
			`controle de estoque`,
			`controle de locais online`,
			`pdv online`,
			`estoque`,
			`recursos`,
			`financeiro`,
			`emitir notas fiscais`,
		]}
      />

      <Header />
 
      <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

        <div className="max-w-6xl mx-auto">

        	<div className="py-8 md:py-15">
            
            <div className="grid gap-3 sm:gap-8">
                
							<div className="md:grid md:grid-cols-12 md:gap-6 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
										src="../images/recursos-topo-westoque.png"
										alt="Sistema wEstoque - PDV online, financeiro e fiscal"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={350}
										className="max-w-full mx-auto h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16">
										<h1 className="text-center text-5xl font-bold" >
                      						Conheça os Recursos do Sistema
										</h1>
										<br/>
										<p className="text-xl text-gray-700 mb-4">O wEstoque é um programa de controle de estoque e ponto de venda (PDV) on-line, o qual proporciona conectividade entre diversas filiais através da internet, centralizando as informações em tempo real de operação.</p>
										<a href="/usar" className="text-md font-bold text-westoque-400 hover:text-westoque-500 hover:underline">Teste Gratuitamente &#8594;</a>
									</div>
								</div>
							</div>




							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 aos-init aos-animate mt-0" data-aos="fade-left">

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min">

                  					<StaticImage
										src="../images/icone-pdv.png"
										alt="Controle de Ponto de Venda e Caixas. Use diversos caixas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										
									/>
									<div className="mt-4 mb-0">
										<span className="bg-clip-text text-transparent bg-westoque-500 text-2xl">Controle de Vendas & PDV</span>
										<p className="text-gray-700 text-md">
											Ganhe agilidade na hora de realizar vendas diretas, com o wEstoque é possível lançar vendas através do código de barras, dar desconto por produto, lançar vendas por vendedor e, trabalhar com múltiplos caixas e muito mais.
										</p>
										<br/>
										<p className="text-right"><a href="/controle-de-vendas-e-pdv" className="text-md font-bold text-indigo-500 hover:text-indigo-700 hover:underline">Saber Mais &#8594;</a></p>
									</div>
								</div>

								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min" >
                  					<StaticImage
										src="../images/icone-multiempresas.png"
										alt="Controle multiplas empresas e lojas. Venda e gerencie todas as suas lojas online."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={78}
									/>
									
									<div className="mt-4 mb-0">
										<span className="bg-clip-text text-transparent bg-indigo-700 text-2xl">Múltiplas Lojas (empresas) & Vários Estoques</span>
										<p className="text-gray-700 text-md">Nosso ERP online é ideal para você que possui mais de uma empresa ou loja e que não quer nem pensar na possibilidade de ter que usar vários sistema para gerenciar o seu negócio.<br/><br/></p>
										<br/>
										<p className="text-right"><a href="/gerencie-multi-empresas" className="text-md font-bold text-indigo-500 hover:text-indigo-700 hover:underline">Saber Mais &#8594;</a></p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min" >
									<StaticImage
										src="../images/icone-controlefinanceiro.png"
										alt="Controle Financeiro. Gerencie suas contas e receitas."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={81}
									/>
									
									<div className="mt-4 mb-0">
										<span className="bg-clip-text text-transparent bg-green-600 text-2xl">Controle Financeiro & Fiscal</span>
										<p className="text-gray-700 text-ml">O módulo financeiro do wEstoque vai te ajuda a fazer a gestão das contas a pagar e receber em tempo real, de forma simples e rápida. As emissões de NFC-e (cupom fiscal) e NF-e (Nota Fiscal Eletrônica) não possuem limitações.</p>
										<br/>
										<p className="text-right"><a href="/sistema-financeiro" className="text-md font-bold text-indigo-500 hover:text-indigo-700 hover:underline">Saber Mais &#8594;</a></p>
									</div>
								</div>


								<div className="bg-white p-5 rounded-lg shadow-md m-3 min-w-min" >
									<StaticImage
										src="../images/icone-controleestoque.png"
										alt="Gerencie vários estoques e localidades. Venda de diversos lugares ao mesmo tempo."
										placeholder="blurred"
										layout="fixed"
										quality={100}
										height={75}
									/>
									
									<div className="mt-4 mb-0">
										<span className="bg-clip-text text-transparent bg-purple-600 text-2xl">Sistema de Controle de Estoque</span>
										<p className="text-gray-700 text-md">Faça a gestão de estoque completa e integrada ao financeiro, vendas e notas fiscais, com essa funcionalidade você pode controlar o giro de estoque, cadastrar produtos, fornecedores e transportadoras e muito mais.</p>
										<br/>
										<p className="text-right"><a href="/controle-de-estoques" className="text-md font-bold text-indigo-500 hover:text-indigo-700 hover:underline">Saber Mais &#8594;</a></p>
									</div>
								</div>

							</div>

							<div className="grid grid-cols-1 text-center p-3">
								<div className="font-semibold text-3xl text-purple-600 mb-2">Nossa solução é completa!</div>
								<h3 className="h3">O sistema de controle de estoque, vendas, financeiro e fiscal que vai controlar todas as áreas da sua loja / empresa.</h3>
							</div>                      



							<div className="md:grid md:grid-cols-12 md:gap-6 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto col-span-12 aos-init aos-animate" data-aos="fade-right">
									
									<div className="md:pr-4 lg:pr-12 xl:pr-16">
											
											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 text-center">
												<div className="flex justify-center -mt-16">
													<StaticImage
													src="../images/icone-seguranca.png"
													alt="Segurança e Privacidade"
													placeholder="blurred"
													layout="fixed"
													quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Segurança e Privacidade: <span className="mt-2 text-gray-700">Seus dados seguros, sem ameaça de vírus e sem perder informações.</span></h2>
												</div>
											</div>

											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 text-center">
												<div className="flex justify-center -mt-16">
													<StaticImage
													src="../images/icone-cloud.png"
													alt="Segurança e Privacidade"
													placeholder="blurred"
													layout="fixed"
													quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">100% Online: <span className="mt-2 text-gray-700">Sem necessidade de instalação, acesse suas informações em tempo real em qualquer dispositivo.</span></h2>
												</div>
											</div>

											<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 text-center">
												<div className="flex justify-center -mt-16">
													<StaticImage
													src="../images/icone-suporte.png"
													alt="Segurança e Privacidade"
													placeholder="blurred"
													layout="fixed"
													quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Suporte Especializado: <span className="mt-2 text-gray-700">Conte com um suporte qualificado pelo Chat e Email.</span></h2>
												</div>
											</div>


                      						<div className="max-w py-4 px-8 bg-white shadow-lg rounded-lg my-20 text-center">
												<div className="flex justify-center -mt-16">
													<StaticImage
													src="../images/icone-tutorial.png"
													alt="Segurança e Privacidade"
													placeholder="blurred"
													layout="fixed"
													quality={100}
													/>
												</div>
												<div>
													<h2 className="text-westoque-400 text-xl font-semibold">Tutoriais Completos: <span className="mt-2 text-gray-700">Nossos artigos e manuais vão te ajudar a extrair o máximo do wEstoque.</span></h2>
												</div>
											</div>

										</div>
									</div>
							</div>


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
                  					<StaticImage
										src="../images/call-pdv-roupas.png"
										alt="Controle sua loja com o sistema wEstoque"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Controle sua Loja</span> de forma <span className="underline decoration-westoque-400">Completa</span> e <br/><span className="underline decoration-sky-500">Segura</span>!
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full">Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> você pode focar no seu negócio!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</section>

      <br/><br/>
			<Footer />


    </main>
  )
}

export default RecursosPage
